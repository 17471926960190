import { mod10, mod11 } from 'checkdigit';
import isEmail from 'validator/es/lib/isEmail';
import isIBAN from 'validator/es/lib/isIBAN';
import isBIC from 'validator/es/lib/isBIC';
import type { InputValidationRule } from 'vuetify';

export type ValidationRules = Record<string, InputValidationRule[]>;

const rules = {
  name: [(v: any) => !!v || 'Vennligst skriv inn navn'],
  feedback: [(v: any) => !!v || 'Ugyldig feedback'],
  email: [(v: any) => !v || isEmail(v) || 'Ugyldig e-postadresse'],
  iban: [(v: any) => !v || isIBAN(v) || 'Ugyldig IBAN'],
  bic: [(v: any) => !v || isBIC(v) || 'Ugyldig BIC / Swift'],
  emailRequired: [(v: any) => !!v || 'E-post påkrevd', (v: any) => (!!v && isEmail(v)) || 'Ugyldig e-postadresse'],
  passwordRequired: [(v: any) => !!v || 'Må ha passord'],
  phone: [
    (v: any) => !v || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(v) || 'Ugyldig telefonnummer',
    (v: any) => !v || (v && v.length > 7) || 'Telefonnummeret må være minst 8 siffer',
  ],
  phoneRequired: [
    (v: any) => !!v || 'Må ha telefonnummer',
    (v: any) => !v || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(v) || 'Ugyldig telefonnummer',
    (v: any) => !v || (v && v.length > 7) || 'Telefonnummeret må være minst 8 siffer',
  ],
  country: [(v: any) => !!v || 'Land påkrevd'],
  postalCodeNonNorwegian: [(v: any) => !!v || 'Postnummer påkrevd'],
  postalCode: [(v: any) => !!v || 'Postnummer påkrevd', (v: any) => (v && v.length === 4) || 'Må være 4 siffer'],
  postalCodeFormat: [(v: any) => !v || v.length === 4 || 'Må være 4 siffer'],
  city: [(v: any) => !!v || 'Sted påkrevd'],
  address: [(v: any) => !!v || 'Adresse påkrevd'],
  companyType: [(v: any) => !!v || 'Vennligst velg selskapsform'],
  productType: [(v: any) => !!v || 'Vennligst velg type'],
  exactlyNineDigits: [(v: any) => /^\d{9}$/.test(v) || 'Må være 9 siffer'],
  organizationNumber: [
    (v: any) => !v || /^[0-9]*$/.test(v) || 'Organisasjonsnummer kan bare inneholde tall',
    (v: any) => !v || v.length === 9 || 'Organisasjonsnummer må være 9 siffer',
  ],
  productNumber: [(v: any) => !!v || 'Vennligst skriv inn produktnummer'],
  bankAccountNumber: [
    (v: string) => !v || /^[\d\s.]*$/.test(v) || 'Kontonummer kan bare inneholde tall',
    (v: string) => !v || (v && v.replace(/\D/g, '').length === 11) || 'Kontonummer må være 11 siffer',
  ],
  norwegianBankAccountFormat: [
    (v: string) => {
      if (!v) {
        return true;
      }
      const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
      const digits = v.split('').map(Number);

      const checksumSum = digits.slice(0, 10).reduce((sum, digit, index) => {
        return sum + digit * weights[index];
      }, 0);

      const checksum = (11 - (checksumSum % 11)) % 11;

      return checksum !== 10 && checksum === digits[10] ? true : 'Ugyldig bankkontonummer';
    },
  ],
  password: [
    (v: any) => !!v || 'Må ha passord',
    (v: any) => (v && v.length > 7) || 'Passordet må være 8 siffer eller lengre',
  ],
  terms: [(v: any) => !!v || 'Vennligst godkjenn vilkår'],
  firstName: [(v: any) => !!v || 'Fornavn påkrevd'],
  lastName: [(v: any) => !!v || 'Etternavn påkrevd'],
  registeredAt: [(v: any) => !!v || 'Må ha dato'],
  vatPaymentFrequency: [(v: any) => (!!v && v !== 'NONE') || 'Må velge betalingsfrekvens'],
  vatPaymentFrequencyCompanyRegister: [(v: any) => !!v || 'Må velge betalingsfrekvens'],
  defaultDueDays: [(v: any) => !!v || 'Standard forfallsfrist kan bare inneholde tall'],
  dateRequired: [(v: any) => !!v || 'Dato påkrevd'],
  salePurchaseDateRequired: [(v: any) => !!v || 'Felt påkrevd'],
  invoiceDateRequired: [(v: any) => !!v || 'Dato påkrevd'],
  descriptionRequired: [(v: any) => !!v || 'Beskrivelse påkrevd'],
  purchaseDescriptionRequired: [(v: any) => !!v || 'Felt påkrevd'],
  amountRequired: [(v: any) => !!v || 'Beløp påkrevd'],
  purchaseAmountRequired: [(v: any) => !!v || 'Felt påkrevd'],
  debitVATRequired: [(v: any) => !!v || 'Debit VAT required'],
  creditVATRequired: [(v: any) => !!v || 'Credit VAT required'],
  productVATRequired: [(v: any) => !!v || 'Felt påkrevd'],
  VATCodeRequired: [(v: any) => !!v || 'Felt påkrevd'],
  accountIdRequired: [(v: any) => !!v || 'Felt påkrevd'],
  paymentPostingAccountIdRequired: [(v: any) => !!v || 'Konto påkrevd'],
  incomeAccountIsRequired: [(v: any) => !!v || 'Felt påkrevd'],
  incomeAccountRequired: [(v: any) => !!v || 'Konto påkrevd'],
  orderRequired: [(v: any) => !!v || 'Order required'],
  ledgerAccountIsRequired: [(v: any) => !!v || 'Konto påkrevd'],
  supplierRequired: [(v: any) => !!v || 'Felt påkrevd'],
  paidFromRequired: [(v: any) => !!v || 'Paid from required'],
  workingHours: [(v: any) => !v || /^([0-9.]{1,4})$/.test(v) || 'only allowed numbers and "," accompanied by numbers '],
  float: [(v: any) => !v || /^(?!0*[.,]?0+$)\d*[.,]?\d+$/.test(v) || 'Ugyldig verdi'],
  number: [(v: any) => !v || /^[0-9]*,?[0-9]*\.?[0-9]*$/.test(v) || 'Kun siffer tillatt'],
  numberAndNegativeNumber: [(v: any) => !v || /^[-+]?[0-9]\d*([.,]\d+)?$/.test(v) || 'Kun siffer tillatt'],
  required: [(v: any) => v === 0 || !!v || 'Felt påkrevd'],
  salaryLine: [(v: any) => !!v || 'Felt påkrevd'],
  quantity: [(v: any) => !!v || 'Felt påkrevd'],

  ftePercentage: [
    (v: any) => !!v || 'Felt påkrevd',
    (v: any) => !v || /^([0-9]|[1-9][0-9]|100)$/.test(v) || 'Kan ikke overstige 100',
  ],
  percentage: [
    (v: any) => !!v || 'Felt påkrevd',
    (v: any) => !v || /^([0-9]|[1-9][0-9]|100)$/.test(v) || 'Kan ikke overstige 100',
  ],
  floatPercentage: [(v: any) => !v || /^([0-9]|[1-9][0-9]|100)$|[0-9]{1,2}[.,]\d{1,2}$/.test(v) || 'Ugyldig verdi'],
  occupationCode: [(v: any) => !!v || 'Felt påkrevd'],
  employmentStartDate: [(v: any) => !!v || 'Felt påkrevd'],
  subUnitOrganisationNumber: [(v: any) => !!v || 'Felt påkrevd'],
  reviewUsersRequired: [(v: any) => v?.length > 0 || 'Felt påkrevd'],
  sex: [(v: any) => !!v || 'Felt påkrevd'],
  nationalId: [(v: any) => !v || /^([0-9.]{11})$/.test(v) || 'Personnummer/D-nummer må være 11 siffer'],
  nationalIdRequired: [
    (v: any) => !!v || 'Felt påkrevd',
    (v: any) => !v || /^([0-9.]{11})$/.test(v) || 'Personnummer/D-nummer må være 11 siffer',
  ],
  comments: [(v: any) => !!v || 'Felt påkrevd'],
  ibanRequired: [(v: any) => !v || isIBAN(v) || 'Ugyldig IBAN', (v: any) => !!v || 'Felt påkrevd'],

  bicRequired: [(v: any) => !v || isBIC(v) || 'Ugyldig BIC / Swift', (v: any) => !!v || 'Felt påkrevd'],
  accountFourDigits: [
    (v: any) => {
      const accNumber = typeof v === 'object' && v ? `${v.accountFullNumber}` : `${v}`;
      return accNumber?.length === 4 || 'Kun 4 siffer tillatt';
    },
  ],
  dnbSSN: [
    (v: any) =>
      /^([a-zA-Z]{2})\d{5}$/.test(v) ||
      'Denne banken krever bruker ID, og ikke fødselsnummer for samtykke til bedrifskonto. Eksempel: XX11111',
  ],
  SSN: [(v: any) => /^[0-9]{11}$/.test(v) || 'Should be 11 digits'],
  nonZeroAmount: [(v: any) => (v && v !== '0') || 'Beløpet kan ikke være 0 kr'],
  nonZero: [(v: any) => (v && v !== '0') || 'Kan ikke være 0'],
  kid: [
    (kid: string | number) => {
      if (!kid) return true;

      const isValidKid =
        kid.toString().length >= 4 && kid.toString().length <= 25 && (mod10.isValid(kid) || mod11.isValid(kid));

      return isValidKid || 'Ugyldig KID';
    },
  ],
  nonNegativeNumber: [
    (v: any) => {
      if (!v) return true;

      const numericValue = typeof v === 'string' ? Number(v.replace(',', '.')) : v;

      return numericValue >= 0 || 'Kun positive beløp';
    },
  ],
} satisfies ValidationRules;

export const conditional = (condition: boolean, validators: any[] = [rules.required]) =>
  condition ? validators.flat() : [];

export const maxLength = (length: number) => (v: any) => !v || v.length <= length || `Maks ${length} tegn`;

export const invoiceLineRules: ValidationRules = {
  description: [(v: any) => !!v || 'Beskrivelse påkrevd'],
  quantity: [(v: any) => /^[-+]?[0-9]*\.?[0-9]+$/.test(v) || 'Antall påkrevd'],
  type: [(v: any) => !!v || 'Type påkrevd'],
  vatCode: [(v: any) => !!v || 'Mva. påkrevd'],
  product: [(v: any) => !!v || 'Produkt påkrevd'],
  price: [(v: any) => /^[-+]?[0-9]*[.,]?[0-9]+$/.test(v) || 'Pris påkrevd'],
  note: [(v: any) => !v || v.length <= 2048 || 'Kommentaren må ikke inneholde mer enn 2048 tegn'],
  incomeAccountRequired: [(v: any) => !!v || 'Felt påkrevd'],
  nonNegativeNumber: rules.nonNegativeNumber,
};

export const paymentsTaxDeductionRowRules: ValidationRules = {
  sum: [(v: any) => /^[0-9]*(\.[0-9]{0,2})?$/.test(v) || 'Cannot be more than two decimal places'],
  salaryLine: [(v: any) => !!v || 'Felt påkrevd'],
  quantity: [(v: any) => !!v || 'Felt påkrevd'],
};

export default rules;
