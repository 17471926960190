<template>
  <BaseDialog v-model="dialogVisibleModel" width="600" persistent>
    <v-card-title>Ny kommentar</v-card-title>
    <v-card-text>
      <p>
        Denne kommentaren blir loggført i endringsloggen på denne siden. Det vil så dato, tid, hvilken bruker, kommentar
        og eventuelt vedlegg. Den brukeren som la til kommentaren kan også slette kommentaren.
      </p>
      <v-form ref="form">
        <v-textarea v-model="comment" :rules="rules.required" label="Kommentar" rows="1" auto-grow />
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-space-between">
      <div>
        <template v-if="!attachment">
          <v-btn color="primary" text @click="openFileSelect">
            <v-icon left>mdi-attachment</v-icon>Legg til vedlegg
          </v-btn>
          <v-file-input ref="fileInput" class="d-none" prepend-icon="" hide-input @change="handleFileChange" />
        </template>
        <v-chip v-else close-icon="mdi-close" close small @click:close="attachment = null">
          <v-icon left small>mdi-file</v-icon> <span class="text-truncate">{{ attachment.name }}</span>
        </v-chip>
      </div>
      <div>
        <v-btn class="mr-2" color="secondary" :disabled="loading" text @click="dialogVisibleModel = false">
          Avbryt
        </v-btn>
        <v-btn color="primary" :loading="loading" :disabled="loading" @click="submit">Ny kommentar</v-btn>
      </div>
    </v-card-actions>
  </BaseDialog>
</template>
<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import BaseDialog from '@/components/BaseDialog.vue';
import type { FetchChangelogParams } from '@/services/changelogService';
import type { ChangeLogCommentCreateBody } from '@/types/schema';
import rules from '@/utils/validationRules';
import { getTextByError } from '@/utils/errorTextHelper';
import { handleError } from '@/utils/shared';

export default defineComponent({
  components: {
    BaseDialog,
  },
  model: {
    prop: 'value',
    event: 'dialog:stateChange',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object as PropType<FetchChangelogParams>,
      required: true,
    },
  },
  emits: ['dialog:stateChange', 'save'],
  data: () => ({
    rules,
    comment: null as string | null,
    attachment: null as File | null,
    loading: false,
  }),
  computed: {
    form() {
      return this.$refs.form as any;
    },
    dialogVisibleModel: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        if (!value) this.reset();

        this.$emit('dialog:stateChange', value);
      },
    },
  },
  methods: {
    openFileSelect() {
      (this.$refs.fileInput as any).$refs.input.click();
    },
    handleFileChange(file: File) {
      this.attachment = file;
    },
    reset() {
      this.attachment = null;
      this.comment = null;
    },
    async submit() {
      if (!this.form.validate()) return;

      this.loading = true;

      const payload: ChangeLogCommentCreateBody = {
        ...this.params,
        comment: this.comment as string,
      };

      if (this.attachment) {
        try {
          const { id } = await this.$api.file.uploadFile(this.companyId, this.attachment);
          payload.fileId = id;
        } catch (e) {
          this.$snackbar.warning(getTextByError(e));
          handleError(e);
        }
      }

      try {
        const response = await this.$api.changelog.createComment(this.companyId, payload);

        this.$emit('save', response);
        this.$snackbar.success('Manuell kommentar lagt til');
        this.reset();
      } catch (e) {
        this.$snackbar.error(getTextByError(e));
        handleError(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.v-chip ::v-deep {
  max-width: 300px;
  button.mdi::before {
    color: var(--v-error-base);
  }
}
</style>
