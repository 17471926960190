<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div>
          Handlingslogg
          <HelpButton title="Handlingslogg">
            <template #content>
              <p>
                Handlingsloggen gir en oversikt over tidspunkt, hvilken bruker og hvilke handlinger som er utført på
                ulike funksjoner i Systima.
              </p>
              <p>
                Ved nye endringer oppdateres loggen automatisk. Hvis funksjonen er datostyrt, som for eksempel en
                MVA-rapport, vil det være en egen handlingslogg per periode.
              </p>
              <p>
                Du kan legge til kommentarer og eventuelle vedlegg, som da blir en del av endringsloggen. Dette kan for
                eksempel brukes til ekstra dokumentasjon eller kommunikasjon mellom brukere.
              </p>
            </template>
          </HelpButton>
        </div>
        <v-btn color="primary" @click="commentDialogVisible = true">+ NY KOMMENTAR</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          :loading="loading"
          loading-text="Laster handlingslogg..."
          no-data-text="Ingen handlinger loggført på denne siden"
          fixed-header
          dense
          hide-default-footer
        >
          <template #item.userFullName="{ item }">
            <span class="username">{{ item.userFullName }}</span>
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt, 'DD.MM.YYYY [kl.] H:mm') }}
          </template>
          <template #item.preview="{ item }">
            <v-btn
              v-if="item.metadata.type === 'FILE'"
              :loading="filePreviewLoading === item.metadata.id"
              icon
              @click="handleFilePreview(item.metadata.id)"
            >
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <template #item.actions="{ item }">
            <v-tooltip :disabled="item.metadata.canDelete" open-delay="500" transition="fade-transition" bottom>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-btn
                    v-if="item.metadata.messageType === 'COMMENT'"
                    :disabled="!item.metadata.canDelete"
                    icon
                    @click="deleteCommentId = item.id"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Du kan kun slette dine egne kommentarer</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ChangelogCommentDialog v-model="commentDialogVisible" :params="queryParams" @save="handleCommentAdd" />
    <BaseDialog v-model="filePreviewDialogVisible" width="1200" height="80%">
      <v-card-title>Attachment preview</v-card-title>
      <v-card-text style="height: 80vh">
        <PDFPreview v-if="filePreviewData" :raw-data="filePreviewData" />
      </v-card-text>
    </BaseDialog>
    <BaseDialog :value="!!deleteCommentId" width="560" @input="deleteCommentId = null">
      <DeleteDialog
        modal-title="Slett kommentar"
        modal-text="Er du sikker på at du vil slette kommentar? Kommentaren kan ikke gjenopprettes."
        :commit-delete="confirmDeleteComment"
        :loading="deleteCommentLoading"
      />
    </BaseDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import HelpButton from '@/components/HelpButton.vue';
import ChangelogCommentDialog from './ChangelogCommentDialog.vue';
import { getTextByError } from '@/utils/errorTextHelper';
import { formatDate, getDefaultPeriod, handleError } from '@/utils/shared';
import type { ChangeLogResponse } from '@/types/schema';
import { getIntervalCurrentWeek } from '../PeriodPicker/utils/intervals';
import type { FetchChangelogParams } from '@/services/changelogService';
import PDFPreview from '@/components/PDFPreview.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';

export default defineComponent({
  components: {
    ChangelogCommentDialog,
    HelpButton,
    PDFPreview,
    BaseDialog,
    DeleteDialog,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [] as ChangeLogResponse[],
    loading: false,
    deleteCommentLoading: false,
    filePreviewLoading: null as string | null,
    commentDialogVisible: false,
    deleteCommentId: null as string | null,
    filePreviewDialogVisible: false,
    filePreviewData: null as ArrayBuffer | null,
    headers: [
      { text: 'Tidspunkt', value: 'createdAt', class: 'text-no-wrap', cellClass: 'text-no-wrap', width: 100 },
      { text: 'Utført av', value: 'userFullName', width: '20%' },
      { text: '', value: 'preview', width: 36, class: 'pa-0', cellClass: 'pa-0' },
      { text: 'Handling', value: 'logMessage' },
      { text: '', value: 'actions', width: 70 },
    ],
  }),
  computed: {
    queryParams(): FetchChangelogParams {
      const { name, params, query } = this.$route;

      switch (name) {
        case 'PurchaseEdit':
          return { type: 'PURCHASE', id: params.purchaseId };
        case 'ManualTransaction':
          return { type: 'MANUAL', id: params.manualTransactionId };
        case 'EditSale':
          return { type: 'INCOME', id: params.saleId };
        case 'EditEmployee':
          return { type: 'EMPLOYEE', id: params.id };
        case 'Invoice':
          return { type: 'INVOICE', id: params.id };
        case 'LockPeriodSettings':
          return { type: 'LOCK_PERIOD' };
        case 'SalaryEdit':
          return { type: 'SALARY', id: params.salaryId };
        case 'SalaryTermSettlement':
          return { type: 'TERM_SETTLEMENT', period: query.period as string };
        case 'BankReconciliationShow':
          return { type: 'BANK_RECONCILIATION', id: query.bankReconciliationId as string };
        case 'MVAReportIndex': {
          const { vatPaymentFrequency } = this.$store.getters.getCompanyById(this.companyId);
          const period = (query.period as string) || getDefaultPeriod(vatPaymentFrequency === 'BIMONTHLY');

          return { type: 'VAT_REPORT', period };
        }
        case 'Timesheet': {
          const weekOf = (query.dateFrom as string) || getIntervalCurrentWeek().dateFrom;

          return {
            type: 'TIMESHEET',
            employmentId: query.TT_employmentId as string,
            weekOf,
          };
        }
        default:
          console.error(`Route ${name} is not supported`);
          throw new Error();
      }
    },
  },
  watch: {
    open: {
      handler(value: boolean) {
        if (value) this.fetchChangelog();
      },
      immediate: true,
    },
    filePreviewDialogVisible(value: boolean) {
      if (!value) this.filePreviewData = null;
    },
    $route() {
      this.items = [];
    },
  },
  methods: {
    formatDate,
    async fetchChangelog() {
      this.loading = true;

      try {
        this.items = await this.$api.changelog.fetch(this.companyId, this.queryParams);
      } catch (e) {
        this.$snackbar.error(getTextByError(e));
        handleError(e);
      } finally {
        this.loading = false;
      }
    },
    async handleFilePreview(fileId: string) {
      this.filePreviewLoading = fileId;

      try {
        this.filePreviewData = await this.$api.file.downloadFile(this.companyId, fileId);
        this.filePreviewDialogVisible = true;
      } catch (e) {
        this.$snackbar.error(getTextByError(e));
        handleError(e);
      } finally {
        this.filePreviewLoading = null;
      }
    },
    handleCommentAdd(comment: ChangeLogResponse) {
      this.items.unshift(comment);
      this.commentDialogVisible = false;
    },
    async confirmDeleteComment() {
      if (!this.deleteCommentId) return;

      this.deleteCommentLoading = true;

      try {
        await this.$api.changelog.deleteComment(this.companyId, this.deleteCommentId);

        this.items = this.items.filter(({ id }) => id !== this.deleteCommentId);
        this.deleteCommentId = null;

        this.$snackbar.success('Kommentar slettet');
      } catch (e) {
        this.$snackbar.error(getTextByError(e));
        handleError(e);
      } finally {
        this.deleteCommentLoading = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
::v-deep .v-data-table--fixed-header > .v-data-table__wrapper {
  max-height: calc(100vh - 200px);
}

.username {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
