import type { UIOptionsPathParams } from '@/types/schema';
import api from '@/utils/api';

export type Scope = UIOptionsPathParams['scope'];
export type UIOptions = Record<string, boolean>;

const availableOptions: Record<Scope, Readonly<string[]>> = {
  manual_transaction: [
    'posting_description',
    'voucher_description',
    'posting_date',
    'currency',
    'project',
    'department',
  ],
  sale: ['project', 'department'],
  purchase: ['project', 'department'],
};

const fill = (scope: Scope, raw: string[]): UIOptions =>
  availableOptions[scope].reduce((acc, item) => ({ ...acc, [item]: raw.includes(item) }), {});

export const UIService = {
  async getOptions(companyId: string, scope: Scope, toObject = true): Promise<UIOptions | string[]> {
    const result: string[] = await api({
      method: 'get',
      url: `companies/${companyId}/ui_options/${scope}`,
    });

    return toObject ? fill(scope, result) : result;
  },
  async updateOptions(companyId: string, scope: UIOptionsPathParams['scope'], data: UIOptions): Promise<UIOptions> {
    const result: string[] = await api({
      method: 'put',
      url: `companies/${companyId}/ui_options/${scope}`,
      data: Object.keys(data).filter((key) => data[key]),
    });

    return fill(scope, result);
  },
};
