import mitt from 'mitt';
import type { FileResponse } from '@/types/schema';

type FileId = FileResponse['id'];

type Events = {
  fileUploaded: FileId;
  bankRecFileUploaded: FileId;
  fileDeleted: FileId | undefined;
};

export default mitt<Events>();
