import api from '@/utils/api';
import type { ChangeLogCommentCreateBody, ChangeLogResponse } from '@/types/schema';

export type FetchChangelogParams =
  | {
      type: 'PURCHASE' | 'MANUAL' | 'INCOME' | 'EMPLOYEE' | 'SALARY' | 'BANK_RECONCILIATION' | 'INVOICE';
      id: string;
    }
  | { type: 'LOCK_PERIOD' }
  | { type: 'VAT_REPORT' | 'TERM_SETTLEMENT'; period: string }
  | { type: 'TIMESHEET'; employmentId: string; weekOf: string };

export const ChangelogService = {
  fetch(companyId: string, params: FetchChangelogParams): Promise<ChangeLogResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/changelog`,
      params,
    });
  },
  createComment(companyId: string, data: ChangeLogCommentCreateBody): Promise<ChangeLogResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/changelog`,
      data,
    });
  },
  deleteComment(companyId: string, commentId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/changelog/${commentId}`,
    });
  },
};
