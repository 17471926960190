<template>
  <v-menu v-if="isAccountantUser" bottom offset-y content-class="menu-round" :close-on-content-click="false">
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-card width="320px">
      <v-card-title>Hjelp</v-card-title>
      <v-card-text>
        <div class="d-flex">
          <div class="pr-2">
            <v-icon>mdi-help-circle-outline</v-icon>
          </div>
          <div>
            <div>Hjelpesenter</div>
            <div>Guider og videoer</div>
            <div>
              <a href="https://hjelp.systima.no/support/home" target="_blank" class="text-decoration-underline">
                Klikk her
              </a>
            </div>
          </div>
        </div>
        <v-divider class="my-3" />
        <div class="d-flex">
          <div class="pr-2">
            <v-icon>mdi-phone-outline</v-icon>
          </div>
          <div>
            <div>Support kun for regnskapsførerne</div>
            <div>Arbeidsdager, 09:00 - 16:00</div>
            <div>Tlf: 69 10 90 62</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
  <v-btn v-else icon href="https://hjelp.systima.no/support/home" target="_blank">
    <v-icon>mdi-help-circle-outline</v-icon>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { UserResponse } from '@/types/schema';

export default defineComponent({
  computed: {
    isAccountantUser() {
      const user = this.$store.getters.getUser as UserResponse | null;

      return !!user?.accountingFirmId;
    },
  },
});
</script>
