import api from '@/utils/api';
import type {
  NotificationResponse,
  UserChangePasswordBody,
  UserCreateBody,
  UserNotificationUpdateBody,
  UserResponse,
  UserUpdateBody,
} from '@/types/schema';

const basePath = 'users';

export const UserService = {
  getUser(): Promise<UserResponse> {
    return api({
      method: 'get',
      url: `${basePath}/me`,
      ignoreCache: true,
    });
  },
  createUser(data: UserCreateBody): Promise<UserResponse> {
    return api({
      method: 'post',
      url: basePath,
      data,
      silenceErrorAlert: true,
    });
  },
  updateUser(data: UserUpdateBody): Promise<UserResponse> {
    return api({
      method: 'patch',
      url: `${basePath}/me`,
      data,
    });
  },
  updateUserPassword(data: UserChangePasswordBody): Promise<void> {
    return api({
      method: 'patch',
      url: `${basePath}/me/password`,
      data,
    });
  },
  sendConfirmation(): Promise<void> {
    return api({
      method: 'post',
      url: `${basePath}/me/send-confirmation`,
    });
  },
  getNotifications(): Promise<NotificationResponse[]> {
    return api({
      method: 'get',
      url: `${basePath}/me/notifications`,
    });
  },
  updateNotification(id: string, data: UserNotificationUpdateBody): Promise<void> {
    return api({
      method: 'patch',
      url: `${basePath}/me/notifications/${id}`,
      data,
    });
  },
  resetUnreadNotification(): Promise<void> {
    return api({
      method: 'patch',
      url: `${basePath}/me/reset-unread-notifications`,
    });
  },
};
