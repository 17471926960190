import { OccupationService } from '@/services/occupationService';

const getDefaultState = () => ({
  occupations: [],
});

const state = getDefaultState();

const getters = {
  getOccupations(state) {
    return state.occupations.map((occupation) => {
      const searchboxTitle =
        `${occupation.code}` +
        ` - ` +
        `${occupation.name}`.charAt(0).toUpperCase() +
        `${occupation.name}`.slice(1).toLowerCase();
      const listSearchTitle = `${occupation.number} ${occupation.name}`.toLowerCase();

      return {
        ...occupation,
        searchboxTitle,
        listSearchTitle,
      };
    });
  },
};

const actions = {
  async fetchOccupations({ commit }) {
    const occupations = await OccupationService.getOccupations();
    commit('setOccupations', occupations);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setOccupations(state, occupations) {
    state.occupations = occupations;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
