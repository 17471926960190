<template>
  <v-app class="unauthorized-layout">
    <AppSnackbar />
    <v-main style="background-color: #f2f3f8">
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppSnackbar from '@/components/app/AppSnackbar.vue';

export default defineComponent({
  components: {
    AppSnackbar,
  },
});
</script>
