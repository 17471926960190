import api from '@/utils/api';
export const ImportContactsService = {
  import(companyId: string, file: File): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/importcontacts`,
      file,
    });
  },
  getSupportedFormats(): Promise<string[]> {
    return api({
      method: 'get',
      url: '/importcontacts/supported-parsers',
    });
  },
  downloadTemplate(): Promise<ArrayBuffer> {
    return api({
      method: 'get',
      url: `importcontacts/template`,
      responseType: 'arraybuffer',
      ignoreCache: true,
    });
  },
};
