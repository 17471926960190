import { UserService } from '@/services/userService';

const state = {
  notifications: [],
};

const actions = {
  async getUser({ commit }) {
    const userResponse = await UserService.getUser();
    const mode = userResponse ? userResponse.mode || 'SIMPLE' : 'SIMPLE';
    commit('theme/setAdvancedMode', mode === 'ADVANCED', { root: true });
    commit('set', { currentUser: userResponse }, { root: true });
    return userResponse;
  },
  async updateUser({ commit }, data) {
    const userResponse = await UserService.updateUser(data);
    commit('set', { currentUser: userResponse }, { root: true });
    return userResponse;
  },
  updateUserPassword(context, data) {
    return UserService.updateUserPassword(data);
  },
  sendConfirmation() {
    return UserService.sendConfirmation();
  },
  async getNotifications({ commit }) {
    const notifications = await UserService.getNotifications();

    commit(
      'setNotifications',
      notifications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    );
  },
  markNotificationAsViewed(context, { notificationId }) {
    return UserService.updateNotification(notificationId, { viewed: true });
  },
  resetUnreadNotification() {
    return UserService.resetUnreadNotification();
  },
};
const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
};
const getters = {
  notifications: (state) => state.notifications,
  unreadNotifications: (state) => state.notifications.filter((notification) => !notification.viewed),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
