<template>
  <v-select
    v-model="country"
    v-bind="$attrs"
    :prepend-inner-icon="prependInnerIcon"
    :label="label"
    :items="countries"
    no-data-text="Ingen land"
  />
</template>

<script>
import countries from '@/utils/countries';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Velg land',
    },
    prependInnerIcon: {
      type: String,
      default: 'mdi-earth',
    },
  },
  data: () => ({
    countries,
  }),
  computed: {
    country: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
