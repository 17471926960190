import api from '@/utils/api';
import type { VoucherReportQuery, VoucherReportResponse } from '@/types/schema';
import type { PaginatedResponse } from '@/utils/pagination';

export const VoucherService = {
  getVouchers(companyId: string, filters: VoucherReportQuery): Promise<PaginatedResponse<VoucherReportResponse>> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vouchers`,
      params: filters,
      abortKey: 'getVouchers',
    });
  },
};
